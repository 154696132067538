<template>
    <div id="lobby">
        <transition
            enter-active-class="animate__animated animate__slideInDown animate__faster"
            leave-active-class="animate__animated animate__slideOutUp animate__faster"
            mode="out-in"
        >
            <div id="errorMessage" v-if="message != null && message != '' && message != undefined">
                <p class="errorText" v-if="message == 'full'">game is full</p>
                <p class="errorText" v-if="message == 'undefined'">There is no game with this code.</p>
                <p class="errorText" v-if="message == 'locked'">{{ lockedText }}</p>
                <p class="errorText" v-if="message == 'username'">Please enter a name!</p>
                <p class="errorText" v-if="message == 'email'">Please enter a email!</p>
                <p class="errorText" v-if="message == 'profession'">Please enter a profession!</p>
            </div>
        </transition>
        <div id="banner" >
            <img id="img" unselectable="on" class="animate__animated animate__backInDown" src='../assets/first8.png'>
        </div>
        <!-- <div id="creator" >
            <img id="creatorImage" unselectable="on" class="animate__animated animate__fadeIn" src='../assets/first8.png'>
        </div> -->
        <transition
            enter-active-class="animate__animated animate__fadeIn animate__faster"
            leave-active-class="animate__animated animate__fadeOut animate__faster"
            mode="out-in"
        >
            <div class="centerContainer" v-if="getGameType() === 'undefined' || getGameType() === 'full' || getGameType() === undefined || getGameType() === 'locked'">
                <div id="submitContainer" class="animate__animated animate__jackInTheBox">
                <input ref="gamePin" class="input" placeholder="Secret code" @keyup.enter="searchGamePin" v-on:input="removeError" @keypress="restrictChars($event)"/>
                    <div id="submitButton" v-on:click="searchGamePin">
                        <p class="submitText" title="Send secret code">Check Code</p>
                    </div>
                </div>
            </div>
            <div class="centerContainer" v-else-if="getGameType() !== 'undefined' || getGameType() !== 'full' || getGameType() !== undefined || getGameType() !== 'locked'">
                <div id="submitContainer">
                    <input class="input" ref="userName" placeholder="Name" v-on:input="removeError" @keyup.enter="enterGame"/>
                    <input class="input" ref="email" placeholder="Email" v-on:input="removeError" @keyup.enter="enterGame"/>
                    <input class="input" ref="profession" placeholder="Profession" v-on:input="removeError" @keyup.enter="enterGame"/>
                    <div id="submitButton" v-on:click="enterGame">
                        <p class="submitText" title="Join game">Lets Go!</p>
                    </div>
                </div>
            </div>
        </transition>
        <transition
            enter-active-class="animate__animated animate__fadeIn animate__faster"
            leave-active-class="animate__animated animate__fadeOut animate__faster"
            mode="out-in"
        >
            <div id="createGameContainer" v-if="getGameType() === 'undefined' || getGameType() === 'full' || getGameType() === undefined || getGameType() === 'locked'">
                <div id="button" class="animate__animated animate__backInUp" v-on:click="redirectToCreator">
                    <p id="buttonText" title="Create Game">Create Game</p>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../store'
import types from '../store/modules/game/types'
import chatTypes from '../store/modules/chat/types'
import generalTypes from '../store/types'
import { ref, computed, onBeforeMount, onMounted } from 'vue'
import router from '../router'

export default {
    setup () {
        const userName = ref()
        const email = ref()
        const profession = ref()
        const gamePin = ref()
        const message = ref(undefined)
        const now = ref(undefined)
        const getters = computed(() => {
            return {
                ...mapGetters('game', [
                    'getGameType',
                    'getStartTimeout',
                    'getTimeout'
                ])
            }
        })

        const lockedText = computed(() => {
            const difference = Math.round(store.getters['game/getTimeout'] - ((now.value - store.getters['game/getStartTimeout']) / 1000))
            return `You have reached your maximum attempts. Please wait ${difference} seconds`
        })

        onBeforeMount(() => {
            if (store.state.game.gameType) {
                store.dispatch(`${store.state.game.gameType}/${types.ONUNMOUNT}`)
                store.dispatch(`chat/${types.ONUNMOUNT}`)
                store.dispatch('game/' + types.RESET_GAMETYPE)
            }
            if (store.state.websocket.client) {
                store.dispatch('game/' + types.ONMOUNT)
            }
        })

        onMounted(() => {
            gamePin.value.focus()
        })

        store.watch(
            (state, getters) => getters['game/getGameType'],
            (newVal, oldVal) => {
                if (newVal === 'undefined' || newVal === 'full' || newVal === 'locked') {
                    message.value = newVal
                    now.value = new Date()
                    gamePin.value.classList.remove('animate__jackInTheBox')
                    gamePin.value.classList.remove('animate__shakeX')
                    setTimeout(() => {
                        gamePin.value.classList.add('animate__shakeX')
                        store.dispatch('game/' + types.RESET_GAMETYPE)
                    }, 10)
                } else {
                    if (newVal !== undefined) {
                        setTimeout(() => {
                            userName.value.focus()
                        }, 900)
                    }
                }
            }
        )

        const enterGame = () => {
            if (userName.value.value === '' || userName.value.value === undefined) {
                message.value = 'username'
            } else if (email.value.value === '' || email.value.value === undefined || !email.value.value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                message.value = 'email'
            } else if (profession.value.value === '' || profession.value.value === undefined) {
                message.value = 'profession'
            } else {
                store.dispatch(`${store.getters['game/getGameType']}/${generalTypes.ONMOUNT}`)
                store.dispatch(`chat/${chatTypes.ONMOUNT}`)
                store.dispatch(`${store.getters['game/getGameType']}/${generalTypes.ENTER_GAME}`, { name: userName.value.value, email: email.value.value, profession: profession.value.value })
                router.push(`/${store.getters['game/getGameType']}`)
            }
        }

        const removeError = () => {
            message.value = ''
        }

        const searchGamePin = () => {
            if (gamePin.value.value !== '') {
                store.dispatch('game/' + types.CHECK_GAMEPIN, gamePin.value.value)
            }
        }
        const redirectToCreator = () => {
            router.push('/creator')
        }

        const restrictChars = (event) => {
            if (event.charCode === 0 || !/\d/.test(String.fromCharCode(event.charCode))) {
                event.preventDefault()
            }
            return true
        }

        return { ...getters.value, redirectToCreator, gamePin, userName, searchGamePin, enterGame, message, removeError, restrictChars, lockedText, email, profession }
    }
}
</script>

<style scoped>
    @media (min-width:320px){
        #lobby{
            width: 85vw;
        }

        .input{
            width: 230px;
            height: 50px;
            font-size: 25px;
        }

        #submitButton{
            width: 250px;
            height: 60px;
        }

        .submitText{
            font-size: 25px;
            line-height: 60px;
        }

        #buttonText{
            font-size: 15px;
            line-height: 30px;
        }

        #button{
            visibility: hidden;
            width: 150px;
            height: 30px;
        }

        .errorText{
            font-size: 13px;
        }
    }

    @media (min-width:1025px) {
        #lobby{
            width: 800px;
        }

        .input{
            width: 300px;
            height: 70px;
            font-size: 40px;
        }

        #submitButton{
            width: 320px;
            height: 70px;
        }

        .submitText{
            font-size: 40px;
            line-height: 70px;
        }

        #buttonText{
            font-size: 30px;
            line-height: 40px;
        }

        #button{
            visibility: visible;
            width: 200px;
            height: 40px;
        }

        .errorText{
            font-size: 25px;
        }
    }

    #lobby{
        margin: auto;
        height: 100vh;
        border: 0;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.096);
        box-shadow: 0px 0px 50px black;
        display: flex;
        flex-direction: column;
        backdrop-filter: blur(5px)
    }

    #img{
        width: 90%;
        max-width: 600px;
        margin-top: 100px;
        height: auto;
        padding-left: 5%;
        padding-right: 5%;
        user-select: none;
        pointer-events: none;
    }

    #banner{
        width: fit-content;
        margin: auto;
        padding-left: 10px;
        padding-right: 10px;
        height: 180px;
    }

    .centerContainer{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: fit-content;
    }

    .input{
        border: 0px;
        color: white;
        padding-left: 10px;
        padding-right: 10px;
        background-color: rgba(0, 0, 0, 0.0);
        text-align: center;
        border-radius: 10px;
    }

    .input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgb(202, 202, 202);
        opacity: 0.7; /* Firefox */
    }

    .input:focus{
        outline: 0px;
    }

    #createGameContainer{
        width: fit-content;
        margin: auto;
        height: 180px
    }

    #button{
        margin-top: 70px;
        background-color: rgba(128, 128, 128, 0.3);
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgb(39, 39, 39);
        transition: all .2s ease-in-out;
    }

    #buttonText{
        margin: 0;
        text-align: center;
        color: rgb(218, 218, 218);
        user-select: none;
    }

    #button:hover{
        cursor: pointer;
        background-color: rgba(77, 77, 77, 0.3);
    }

    #websocketContainer{
        position: absolute;
        left: 10px;
        bottom: 10px;
    }

    #errorMessage{
        position: absolute;
        width: inherit;
        height: 40px;
        text-align: center;
        background-color: rgba(255, 0, 0, 0.308);
        box-shadow: 0px -10px 30px rgba(37, 37, 37, 0.541);
    }

    .errorText{
        width: fit-content;
        margin: auto;
        color: rgba(255, 255, 255, 0.849);
        line-height: 40px;
    }

    #submitButton{
        background-color: rgba(52, 250, 95, 0.452);
        text-align: center;
        padding-top: 1px;
        padding-bottom: 1px;
        border-radius: 9px;
        transition: all .2s ease-in-out;
    }

    #submitButton:hover{
        cursor: pointer;
        background-color: rgba(52, 250, 95, 0.63);
    }

    .submitText{
        margin: 0;
        color: rgb(209, 209, 209);
        user-select: none;
    }

    #submitContainer{
        width: fit-content;
        height: fit-content;
        display: flex;
        border-radius: 15px;
        background-color: rgba(0, 0, 0, 0.4);
        flex-direction: column;
        box-shadow: 0px 0px 10px rgb(0, 0, 0);
    }

    #creator{
        position: fixed;
        bottom: 10px;
        right: 10px;
    }

    #creatorImage{
        height: 20px;
    }
</style>
